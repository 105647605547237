import React, { useState } from "react";
import "./Home.scss";
import Banner from "../components/Banner";
import { getBanner } from "../components/Blogdata.js";

import homebanner from "../assets/home/homebanner.png";
import Aboutsection from "../components/Aboutsection";
import TestimonialSec from "../components/TestimonialSec";
import Videosec from "../components/Videosec";
import Blog from "../components/Blog";
import Footer from "../components/Footer";
import { useEffect } from "react";
import Taxsolution from "../components/Taxsolution";
import { Helmet } from "react-helmet";

function Home() {
  // const [banner, setBanner] = useState(false);
  // useEffect(() => {
  //   getBanner("home").then((res) => {
  //     setBanner(res.banner);
  //   });
  // }, []);
  return (
    <div className="home">
      <Helmet>
        <title>
          ClearStone Group - Accounting | Financial Planning | Lending - Home
        </title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
          content="To keep the complete employee’s financial transaction details, our payroll outsourcing keeps track of employees' salary, wages, overtime compensation, and fringe benefits."
        />
      </Helmet>
      <Banner
        img="assets/banners/EditBanner/5.jpg"
        sub="Accounting & Finance"
        main="Business Solution"
        desc="ClearStone Group is your accounting and finance business partner offering business, tax advisory, bookkeeping and payroll services to provide insights and alleviate pressure points allowing you to focus on growing your business. "
        btn="Load More"
      />
      <Aboutsection />
      {/* <Videosec /> */}
      <Taxsolution />
      <TestimonialSec />
      {/* <Blog /> */}
    </div>
  );
}

export default Home;
