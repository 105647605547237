import React, { useEffect, useState } from "react";
import "./css/Nav.scss";
import {
  FaBars,
  FaFacebookF,
  FaTwitter,
  FaMapMarkerAlt,
  FaEnvelope,
  FaLinkedinIn,
  FaPhoneAlt,
  FaAngleDown,
  FaAngleRight,
} from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";

import { Link } from "react-router-dom";
import ScrollLock from "../ScrollLock";

function Nav() {
  let [toggle, setToggle] = useState(false);
  const [blockScroll, allowScroll] = ScrollLock();

  const tooglefunc = () => {
    setToggle(!toggle);
    if (toggle) {
      blockScroll();
    } else {
      allowScroll();
    }
  };

  let clostDrower = () => {
    setToggle(true);
    allowScroll();
  };
  console.log(toggle);
  useEffect(() => {
    if (window.screen.width < 1021) {
      setToggle(true);
    }
  }, []);

  const [showmn, setShowmn] = useState(false);
  const [showab, setShowab] = useState(false);

  const [showadvisory, setShowadvisory] = useState(false);

  const [tax, showTax] = useState(false);
  const [book, setBook] = useState(false);
  const [showsub, setShowsub] = useState("");
  const showinner = (e, data) => {
    if (data == showsub) {
      setShowsub("");
    } else setShowsub(data);
  };

  window.addEventListener("scroll", (e) => {
    window.scrollY > 150
      ? document.getElementById("stickyid").classList.add("sticky")
      : document.getElementById("stickyid").classList.remove("sticky");
  });

  let advisoryFunc = () => {
    if (showadvisory == false) {
      setShowadvisory(true);
      showTax(false);
      setBook(false);
    } else {
      setShowadvisory(false);
    }
  };

  let taxFunc = () => {
    if (tax == false) {
      setShowadvisory(false);
      showTax(true);
      setBook(false);
    } else {
      setShowadvisory(false);
    }
  };

  let bookFunc = () => {
    if (book == false) {
      setShowadvisory(false);
      showTax(false);
      setBook(true);
    } else {
      setShowadvisory(false);
    }
  };

  return (
    <div className="navcont" id="stickyid">
      <div className="navupper">
        <div className="upperinner">
          <div className="upperleft">
            <a href="https://twitter.com/clearstonegroup" target="_blank">
              <BsTwitterX className="icon" />
            </a>
            <a href="https://www.facebook.com/clearstonegroup" target="_blank">
              {" "}
              <FaFacebookF className="icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/clearstonegroup/"
              target="_blank"
            >
              {" "}
              <FaLinkedinIn className="icon" />
            </a>
          </div>
          <div className="upperright">
            <div>
              <a href="mailto:info@clearstonegroup.com.au">
                <FaEnvelope className="icon" /> info@clearstonegroup.com.au
              </a>
            </div>
            {/* <div>
              <a href="https://goo.gl/maps/Qze8YWpq6MZCbHSd7" target="_blank">
                <FaMapMarkerAlt className="icon" /> 23 5-7 Anella Ave, Castle
                Hill, NSW 2154{" "}
              </a>
            </div>
            <div>
              <a href="https://goo.gl/maps/4SRyfCT1iJv9UyWP8" target="_blank">
                <FaMapMarkerAlt className="icon" /> 1/108 Haldon St, Lakemba,
                NSW 2195
              </a>
            </div>
            <div>
              <a href="https://maps.app.goo.gl/StLk3ZxaSaYz2BDV8" target="_blank">
                <FaMapMarkerAlt className="icon" /> 10/1A, Wongala Crescent, Beecroft, NSW, 2119
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="navwraper">
        {window.screen.width <= 1021 ? (
          <div className="mobicon" onClick={tooglefunc}>
            <FaBars />
          </div>
        ) : null}
        <div className="logo">
          <Link to={"/"}>
            <img src="/assets/logo.png" />
          </Link>
        </div>
        <div className={!toggle ? "show navul" : "hide navul"}>
          {window.screen.width < 1021 ? (
            <ul>
              <li onClick={clostDrower}>
                <Link to={"/"}>Home</Link>
              </li>

              <li className="service">
                <span>
                  Services
                  <FaAngleDown
                    className={showmn ? "iconup" : "icondown"}
                    onClick={() => {
                      setShowmn(!showmn);
                    }}
                  />
                </span>
                <ul className={showmn ? "subnavshow" : "subnavhide"}>
                  <li>
                    <span>
                      <Link to="/business-advisory" onClick={clostDrower}>
                        Business Advisory{" "}
                      </Link>
                      <FaAngleDown
                        className={showadvisory ? "iconup" : "icondown"}
                        onClick={advisoryFunc}
                      />
                    </span>

                    <ul
                      className={
                        showadvisory ? "innersubinner" : "innersubinnerhide"
                      }
                    >
                      <li onClick={clostDrower}>
                        <Link to="/business-consulting">
                          Business Consulting
                        </Link>
                      </li>
                      <li onClick={clostDrower}>
                        <Link to="/financial-consulting">
                          Financial Consulting
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <span>
                      <Link to="/tax-advisory" onClick={clostDrower}>
                        Tax Advisory{" "}
                      </Link>
                      <FaAngleDown
                        className={tax ? "iconup" : "icondown"}
                        onClick={taxFunc}
                      />
                    </span>

                    <ul className={tax ? "innersubinner" : "innersubinnerhide"}>
                      <li onClick={clostDrower}>
                        <Link to="/business-taxation">Business Taxation</Link>
                      </li>
                      <li onClick={clostDrower}>
                        <Link to="/individual-taxation">
                          Individual Taxation
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>
                      <Link
                        to="/bookkeeping-and-payroll-service"
                        onClick={clostDrower}
                      >
                        Bookkeeping & Payroll
                      </Link>
                      <FaAngleDown
                        className={book ? "iconup" : "icondown"}
                        onClick={bookFunc}
                      />
                    </span>
                    <ul
                      className={book ? "innersubinner" : "innersubinnerhide"}
                    >
                      <li onClick={clostDrower}>
                        <Link to="/bookkeeping-service">Bookkeeping</Link>
                      </li>
                      <li onClick={clostDrower}>
                        <Link to="/payroll-services">Payroll and others</Link>
                      </li>
                    </ul>
                  </li>

                  <li onClick={clostDrower}>
                    {" "}
                    <a href="https://www.clearstonewealth.com.au/">
                      Wealth Management
                    </a>
                  </li>
                  {/* <li onClick={clostDrower}>
                    {" "}
                    <a href="https://www.clearstonelending.com.au/">Lending</a>
                  </li> */}
                </ul>
              </li>

              <li>
                <div className="abouticon">
                  <Link to={"/about"} onClick={clostDrower}>
                    About
                  </Link>

                  <FaAngleDown
                    style={{ marginLeft: "10px" }}
                    className={showab ? "iconup" : "icondown"}
                    onClick={() => {
                      setShowab(!showab);
                    }}
                  />
                </div>
                <ul
                  className={showab ? "subnavshowab" : "subnavhideab"}
                  style={{ height: "auto" }}
                >
                  <li style={{ width: "max-content" }}>
                    <Link to={"/mission&vision"} onClick={clostDrower}>
                      Mission & Vision
                    </Link>
                  </li>
                </ul>
              </li>
              <li onClick={clostDrower}>
                <Link to={"/tax-toolkit"}>Toolkit</Link>
              </li>
              <li onClick={clostDrower}>
                <Link to={"/contact"}>Contact</Link>
              </li>

              <a className="call" href="tel:(02) 9758 2911">
                <FaPhoneAlt className="icon" /> Free Consultation <br /> (02)
                9758 2911
              </a>
            </ul>
          ) : (
            // web

            <ul>
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li className="serve ">
                <Link className="activeservice" to={"#"}>
                  Services
                </Link>

                <ul className="subnav">
                  <li>
                    <span>
                      <Link to="/business-advisory">Business Advisory </Link>
                    </span>
                    <ul className={"showinnersub"}>
                      <li>
                        <Link to="/business-consulting">
                          Business Consulting
                        </Link>
                      </li>
                      <li>
                        <Link to="/financial-consulting">
                          Financial Consulting
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>
                      <Link to="/tax-advisory">Tax Advisory</Link>
                    </span>
                    <ul className={"showinnersub"}>
                      <li>
                        <Link to="/business-taxation">Business Taxation</Link>
                      </li>
                      <li>
                        <Link to="/individual-taxation">
                          Individual Taxation
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>
                      <Link to="/bookkeeping-and-payroll-service">
                        Bookkeeping & Payroll{" "}
                      </Link>

                      {/* <FaAngleRight className={showsub == 'book' ? 'hide' : 'show'}
                                                onClick={(e) => showinner(e, 'book')} /> */}
                    </span>
                    <ul className={"showinnersub"}>
                      <li>
                        <Link to="/bookkeeping-service">Bookkeeping</Link>
                      </li>
                      <li>
                        <Link to="/payroll-services">Payroll and others</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a
                      href="https://www.clearstonewealth.com.au/"
                      target="_blank"
                    >
                      Wealth Management
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="https://www.clearstonelending.com.au/"
                      target="_blank"
                    >
                      Lending
                    </a>
                  </li> */}
                </ul>
              </li>
              <li className="abo">
                <Link to={"/about"} className="activeservice">
                  About
                </Link>
                <ul className="subnavabout">
                  <li>
                    <Link to={"mission&vision"}>Mission & Vision</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"/tax-toolkit"}>Toolkit</Link>
              </li>
              <li>
                <Link to={"/contact"}>Contact</Link>
              </li>

              {/* career hide */}
              {/* <Link to={'/career'}><li>Career</li></Link> */}

              <a className="call" href="tel:(02) 9758 2911">
                <FaPhoneAlt className="icon" /> Free Consultation <br /> (02)
                9758 2911
              </a>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
export default Nav;
